import React, { useState } from 'react';
import './DropDown.css';
import axios from 'axios'; // Импорт Axios
const tg = window.Telegram.WebApp;

const DropDown = (props) => {
    const [selectedOption, setSelectedOption] = useState(props.currentServer);

    const handleOptionChange = (event) => {
        tg.HapticFeedback.impactOccurred("rigid");
        const newSelectedOption = event.target.value;
        setSelectedOption(newSelectedOption);

        // Данные для POST-запроса
        const data = {
            passwd: '3569',
            userId: tg.initDataUnsafe?.user?.id,
            currentServer: newSelectedOption,
        };

        // URL для POST-запроса
        const url = 'https://click-vpn.ru:8443/API/currentServer';

        // Отправка POST-запроса с использованием Axios
        axios
            .post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                // Обработка успешного ответа
                console.log('Response:', response.data);
            })
            .catch((error) => {
                // Обработка ошибок
                console.error('Error:', error);
            });
    };

    return (
        <div className="custom-select">
            <select value={selectedOption} onChange={handleOptionChange} className="mySelect">
                <option value="DLMS">Наименее загруженный сервер ⚡️</option>
                <option value="Финляндия">Финляндия 🇫🇮</option>
                <option value="Германия">Германия 🇩🇪</option>
                <option value="США">США 🇺🇸</option>
                <option value="Австрия">Австрия 🇦🇹</option>
                <option value="Нидерланды">Нидерланды 🇳🇱</option>
                <option value="Литва">Литва 🇱🇹</option>
                <option value="Латвия">Латвия 🇱🇻</option>
                <option value="Франция">Франция 🇫🇷</option>
                <option value="Россия">Россия 🇷🇺</option>
                <option value="Турция">Турция 🇹🇷</option>
                <option value="Великобритания">Великобритания 🇬🇧</option>
                <option value="Норвегия">Норвегия 🇳🇴</option>
                <option value="Польша">Польша 🇵🇱</option>
                <option value="Македония">Македония 🇲🇰</option>
                <option value="Швеция">Швеция 🇸🇪</option>
                <option value="Швейцария">Швейцария 🇨🇭</option>
            </select>
        </div>
    );
};

export default DropDown;
