import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import './Business.css';
import animServer from './BusinessAnim/Server.json';
import animWorld from './BusinessAnim/World.json';
import animUsers from './BusinessAnim/Users.json';
import animChart from './BusinessAnim/Chart.json';
import animProtocol from './BusinessAnim/Protocol.json';
import {useTelegram} from "../hooks/useTelegram"

export default function Business() {
    const containerRef = useRef(null);
    const {tg} = useTelegram();

    useEffect(() => {
        const container = document.getElementById('animServer'); // Получите элемент, в который встроить анимацию
        const anim = lottie.loadAnimation({
            container: container,
            animationData: animServer, // Подставьте ваш JSON-объект анимации
            loop: true, // Опционально: сделать анимацию цикличной
            autoplay: true, // Опционально: воспроизвести анимацию автоматически
        });

        return () => {
            anim.destroy(); // Опционально: уничтожить анимацию при размонтировании компонента
        };
    }, []);

    useEffect(() => {
        const container = document.getElementById('animWorld'); // Получите элемент, в который встроить анимацию
        const anim = lottie.loadAnimation({
            container: container,
            animationData: animWorld, // Подставьте ваш JSON-объект анимации
            loop: true, // Опционально: сделать анимацию цикличной
            autoplay: true, // Опционально: воспроизвести анимацию автоматически
        });

        return () => {
            anim.destroy(); // Опционально: уничтожить анимацию при размонтировании компонента
        };
    }, []);

    useEffect(() => {
        const container = document.getElementById('animUsers'); // Получите элемент, в который встроить анимацию
        const anim = lottie.loadAnimation({
            container: container,
            animationData: animUsers, // Подставьте ваш JSON-объект анимации
            loop: true, // Опционально: сделать анимацию цикличной
            autoplay: true, // Опционально: воспроизвести анимацию автоматически
        });

        return () => {
            anim.destroy(); // Опционально: уничтожить анимацию при размонтировании компонента
        };
    }, []);

    useEffect(() => {
        const container = document.getElementById('animChart'); // Получите элемент, в который встроить анимацию
        const anim = lottie.loadAnimation({
            container: container,
            animationData: animChart, // Подставьте ваш JSON-объект анимации
            loop: true, // Опционально: сделать анимацию цикличной
            autoplay: true, // Опционально: воспроизвести анимацию автоматически
        });

        return () => {
            anim.destroy(); // Опционально: уничтожить анимацию при размонтировании компонента
        };
    }, []);

    useEffect(() => {
        const container = document.getElementById('animProtocol'); // Получите элемент, в который встроить анимацию
        const anim = lottie.loadAnimation({
            container: container,
            animationData: animProtocol, // Подставьте ваш JSON-объект анимации
            loop: true, // Опционально: сделать анимацию цикличной
            autoplay: true, // Опционально: воспроизвести анимацию автоматически
        });

        return () => {
            anim.destroy(); // Опционально: уничтожить анимацию при размонтировании компонента
        };
    }, []);

    tg.setHeaderColor('#000000')
    tg.expand()

    const handleBuy = () => {
        tg.HapticFeedback.notificationOccurred('success')
        window.open(`https://click-vpn.ru/new_payment_business/${tg.initDataUnsafe?.user?.id}`, "_blank", "noopener noreferrer");
    };

    return (
        <div className="Apps">

            <div className="container" ref={containerRef}>
                <div className="content">
                    <h2 className="business-h2">Click VPN для бизнеса</h2>

                    <div className="premium-features">
                        <div>Персональный сервер со скоростью до 1 Гбит/с!</div>
                        <label>Доступ к серверу ограничен и защищен, поэтому никто, кроме вас не сможет к нему подключиться.</label>
                        <br />
                        <label>Вся скорость, которая предоставляется провайдером - в вашем распоряжении.</label>
                    </div>
                    <div className="anim">
                        <div id="animServer"></div>
                    </div>
                    <br />

                    <div className="premium-features">
                        <div>Любая локация на ваш выбор!</div>
                        <label>Вместе подберем наиболее подходящую для ваших задач локацию.</label>
                    </div>
                    <div className="anim">
                        <div id="animWorld"></div>
                    </div>
                    <br />

                    <div className="premium-features">
                        <div>Неограниченное число пользователей!</div>
                        <label>Вы сможете самостоятельно предоставлять доступ к вашему VPN своим коллегам или друзьям!</label>
                    </div>
                    <div className="anim">
                        <div id="animUsers"></div>
                    </div>
                    <br />

                    <div className="premium-features">
                        <div>Система мониторинга собственной разработки.</div>
                        <label>Мы специально разработали простую систему мониторинга, чтобы сервер всегда был под контролем!</label>
                    </div>
                    <div className="anim">
                        <div id="animChart"></div>
                    </div>

                    <div className="premium-features">
                        <div>Любой VPN протокол.</div>
                        <label>По вашему запросу установим и настроим подключение любым VPN протоколом.</label>
                    </div>
                    <div className="anim">
                        <div id="animProtocol"></div>
                    </div>
                    <br />

                    <div className="premium-features">
                        <h3>Прекрасно! Что дальше?</h3>
                        <div>
                            В течение часа после оплаты с вами свяжется наш менеджер.
                            <br />
                            <br />
                            Вместе подберём локацию, установим всё необходимое программное обеспечение и расскажем как пользоваться.
                            <br />
                            <br />
                            Администрирование сервера берём на себя мы. Вы - пользуетесь и наслаждаетесь скоростью без ограничений.
                            <br />
                            <br />
                        </div>
                    </div>

                </div>
            </div>
            <button className="buy-button-business" onClick={handleBuy}>
                Подключить
            </button>
            <label>Click VPN 2024</label>
        </div>
    );
}
