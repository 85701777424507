import React, { useState, useEffect } from "react";
import "./Load.css"; // Путь к вашему файлу стилей

const Load = () => {
    const [isLoadVisible, setIsLoadVisible] = useState(false);
    const [serverLoad, setServerLoad] = useState([]);

    useEffect(() => {
        // Запрос к серверу для получения информации о нагрузке
        fetch("https://click-vpn.ru:8443/API/DLMS")
            .then((response) => response.json())
            .then((data) => {
                // Отфильтровать null и преобразовать нагрузки в числа
                const validData = data
                    .filter(item => item !== null)
                    .map(item => ({
                        name: item.name,
                        load: Math.round(parseFloat(item.load))
                    }));

                // Сортировка виджетов по нагрузке
                const sortedData = validData.sort((a, b) => a.load - b.load);

                setServerLoad(sortedData);
            })
            .catch((error) => console.error("Error fetching server load:", error));
    }, []); // [] означает, что useEffect будет вызван только один раз после монтирования компонента

    const Parentdiv = {
        height: "7px",
        width: "100%",
        backgroundColor: "whitesmoke",
        borderRadius: "40px",
        margin: "10px 0", // Уменьшил отступ
        overflow: "hidden",
    };

    const Childdiv = (load) => ({
        height: "100%",
        width: `${load}%`,
        backgroundColor: "#20d005",
        borderRadius: "20px",
        textAlign: "right",
        position: "relative",
    });

    const toggleLoadVisible = () => {
        setIsLoadVisible(!isLoadVisible);
    };

    return (
        <div className="load-container">
            <button className="LoadButton" onClick={toggleLoadVisible}>
                Рекомендуемые локации ⬇️
            </button>
            <section className={`Load ${isLoadVisible ? 'visible' : ''}`}>
                {serverLoad.slice(0, 2).map((server) => (
                    <div key={server.name} className="load-widget">
                        {server.name}
                        <div style={Parentdiv}>
                            <div style={Childdiv(server.load)} />
                        </div>
                        <span className="load-percent">{server.load}%</span>
                    </div>
                ))}
            </section>
        </div>
    );
};

export default Load;
