import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';
import Login from "./CRM/login"

import PaymentRedirect from "./components/PaymentRedirect";
import ModelViewer from "./components/premium";
import Business from "./components/Business"
import Load from "./components/Load"

import ConnectOutline from "./components/ConnectOutline"
import ConnectVLESS from "./components/ConnectVLESS"

import Wiki from "./Wiki/Wiki"
import Payment from "./components/payment";
import PaymentPremium from "./components/payment_premium"
import PaymentGift from "./components/payment_gift"
import PaymentBusiness from "./components/payment_business"
import New_lk from "./new_lk/New_lk"
import Settings from "./new_lk/components/settings"
import Location from "./new_lk/components/location"
import Test_location from "./new_lk/components/test_location";

// Динамическая загрузка защищенного компонента
const IndexCRM = lazy(() => import("./CRM/indexCRM"));

//Отключаем масштабирование
const root = ReactDOM.createRoot(document.getElementById('root'));
document.addEventListener('gesturestart', function(e) {
    e.preventDefault();
});

const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    // Дополнительная проверка на валидность токена может быть добавлена здесь
    return !!token;
};

const ProtectedRoute = ({ element }) => {
    return isAuthenticated() ? element : <Navigate to="/login" />;
};

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path='tg' element={<App />}> </Route>
              <Route path='Wiki' element={<Wiki />}> </Route>
              <Route path='paymentRedirect/:id' element={<PaymentRedirect />} > </Route>
              <Route path='Premium' element={<ModelViewer />} > </Route>
              <Route path='Business' element={<Business />} > </Route>
              <Route path='Load' element={<Load />} > </Route>
              <Route path='CRM' element={<ProtectedRoute element={<Suspense fallback={<div>Loading...</div>}><IndexCRM /></Suspense>} />} />
              <Route path='login' element={<Login />} > </Route>
              <Route path="ConnectOutline" element={<ConnectOutline />} />
              <Route path='ConnectVLESS' element={<ConnectVLESS />} > </Route>
              <Route path='New_lk' element={<New_lk />} > </Route>
              <Route path='Settings' element={<Settings />} > </Route>
              <Route path='Location' element={<Location />} > </Route>
              <Route path='Test_location' element={<Test_location />} > </Route>

              <Route path='' element={<Login />} > </Route>
              <Route path='new_payment/:id' element={<Payment />}></Route>
              <Route path='new_payment_premium/:id' element={<PaymentPremium />}></Route>
              <Route path='new_payment_gift/:id' element={<PaymentGift />}></Route>
              <Route path='new_payment_business/:id' element={<PaymentBusiness />}></Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

