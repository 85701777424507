import React, { useState, useEffect } from 'react';
import '../New_lk.css';
import axios from "axios";

const tg = window.Telegram.WebApp;

function Settings() {
    tg.setHeaderColor('#1A659E');
    const [autoPay, setAutoPay] = useState(false);

    useEffect(() => {
        axios.get(`https://click-vpn.ru:8443/API/userAccount/${tg.initDataUnsafe?.user?.id}`)
            .then(response => {
                console.log(response);
                setAutoPay(response.data.autoPayBool); // Убедитесь, что данные извлекаются правильно
                console.log(response.data.autoPayBool);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    /////////////////////////////
    // Отключение масштабирования
    /////////////////////////////

    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);
    /////////////////////////////
    /////////////////////////////

    const BackButton = tg.BackButton;
    BackButton.show();
    BackButton.onClick(function() {
        BackButton.hide();
        tg.HapticFeedback.impactOccurred('rigid');
    });
    tg.onEvent('backButtonClicked', function() {
        window.location.href = '/new_lk';
    });

    const handleSupportClick = () => {
        tg.HapticFeedback.notificationOccurred('success');
        tg.openTelegramLink('https://t.me/click_vpn_help');
    };

    const handleDisableAutoPayment = () => {
        tg.HapticFeedback.notificationOccurred('error');
        tg.showConfirm('Вы уверены, что хотите отключить подписку?', (confirmation) => {
            if (confirmation) {
                const data = {
                    userId: tg.initDataUnsafe?.user?.id,
                };
                axios.post(`https://click-vpn.ru:8443/API/delete_autopay`, data)
                    .then(() => {
                        tg.showAlert(`Подписка отключена.`);
                        setAutoPay(false); // Обновляем состояние на отключение автоплатежа
                    })
                    .catch(error => {
                        console.error('Error disabling subscription:', error);
                        tg.showAlert('Произошла ошибка при отключении подписки. Пожалуйста, попробуйте снова.');
                    });
            }
        });
    };

    const handleGetInstructions = () => {
        tg.HapticFeedback.notificationOccurred('success');

        const data = {
            userId: tg.initDataUnsafe?.user?.id,
            passwd: '3569'
        };

        axios.post('https://click-vpn.ru:8443/API/instructions', data)
            .then(response => {
                console.log('Инструкции отправлены:', response.data);
                tg.close()
            })
            .catch(error => {
                console.error('Ошибка при отправке инструкций:', error);
                tg.showAlert('Ошибка при отправке инструкций. Попробуйте еще раз.');
            });
    };

    return (
        <div className="lk">
            <div className="new-subscription-card">
                <div className="new-subscription-title">
                    Настройки <div className="rotate-emoji">⚙️</div>
                </div>
                <p className="subscription-details">Инструкции по настройке VPN:</p>
                <button className="new-subscription-button-settings" onClick={handleGetInstructions}>
                    <div>Получить инструкции</div>
                    <div>📋</div>
                </button>
                <p className="subscription-details">Помощь в решении проблем:</p>
                <button className="new-subscription-button-settings" onClick={handleSupportClick}>
                    <div>Связаться с поддержкой</div>
                    <div>🧑‍💻</div>
                </button>
                <p className="subscription-details">Вы можете отключить автоплатеж:</p>
                {autoPay ? (
                    <button className="new-subscription-button-settings" onClick={handleDisableAutoPayment}>
                        <div>Отключить подписку</div>
                        <div>❌</div>
                    </button>
                ) : (
                    <p className="subscription-disabled-text">Автоплатеж отключен 💳</p>
                )}
            </div>
        </div>
    );
}

export default Settings;
