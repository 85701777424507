import React, { useState } from 'react';
import './ToggleSwitch.css'; // Подключите свои стили
import axios from 'axios'; // Импорт Axios
const tg = window.Telegram.WebApp;

const ToggleSwitch = ({ initialValue, onChange }) => {
    const [isChecked, setIsChecked] = useState(initialValue);

    const handleToggle = () => {
        const newValue = !isChecked;
        setIsChecked(newValue);
        onChange(newValue);
    };

    return (
        <div className={`toggle-switch ${isChecked ? 'checked' : ''}`} onClick={handleToggle}>
            <div className="toggle-switch-slider"></div>
        </div>
    );
};

const Switch = (props) => {
    const [selectedOption, setSelectedOption] = useState(props.currentServer);

    const handleToggleChange = (isChecked) => {
        tg.HapticFeedback.selectionChanged();
        setSelectedOption(isChecked ? 'VLESS' : 'ShadowSocks');

        const data = {
            passwd: '3569',
            userId: tg.initDataUnsafe?.user?.id,
            currentProtocol: isChecked ? 'VLESS' : 'ShadowSocks',
        };

        const url = 'https://click-vpn.ru:8443/API/currentProtocol';

        axios
            .post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log('Response:', response.data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        setTimeout(() => {
            tg.close();
        }, 700);
    };

    return (
        <div>
            <div className="toggle-switch-container">
                Подключить VLESS
                <ToggleSwitch initialValue={selectedOption === 'VLESS'} onChange={handleToggleChange} />
            </div>

        </div>
    );
};

export default Switch;
