import React, { useState, useEffect } from 'react';
import './test_location.css';
import '../New_lk.css';

const tg = window.Telegram.WebApp;

function Test_location() {
    const [currentFlagIndex, setCurrentFlagIndex] = useState(0);
    const [finalFlag, setFinalFlag] = useState(null);
    const [isSliding, setIsSliding] = useState(true);

    tg.setHeaderColor('#1A659E');

    useEffect(() => {
        const preventTouchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };
        window.addEventListener('touchstart', preventTouchZoom, { passive: false });
        return () => {
            window.removeEventListener('touchstart', preventTouchZoom);
        };
    }, []);

    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);

    const BackButton = tg.BackButton;
    BackButton.show();
    BackButton.onClick(function() {
        BackButton.hide();
    });
    tg.onEvent('backButtonClicked', function() {
        window.location.href = '/new_lk';
    });

    useEffect(() => {
        if (isSliding) {
            const interval = setInterval(() => {
                setCurrentFlagIndex((prevIndex) => (prevIndex + 1) % flags.length); // Симуляция флагов
                tg.HapticFeedback.impactOccurred('rigid');
            }, 1000); // Меняем флаг каждые 1000 мс

            const stopSliding = setTimeout(async () => {
                setIsSliding(false);
                const bestLocation = await fetchBestLocation();
                setFinalFlag(bestLocation);
                tg.HapticFeedback.notificationOccurred('success');

                // Отображаем Telegram Alert с финальной локацией
                tg.showAlert(
                    `Твой выбор - ${bestLocation.name} ${bestLocation.emoji}\n\nЗадержка и трафик на этой локации минимальны, что гарантирует оптимальную скорость и надежность соединения.\n\nЛокацию можно выбрать в приложении, через которое ты подключаешься к VPN.`
                );
            }, 5000); // Остановим через 5 секунд

            return () => {
                clearInterval(interval);
                clearTimeout(stopSliding);
            };
        }
    }, [isSliding]);

    const fetchBestLocation = async () => {
        try {
            const response = await fetch('https://click-vpn.ru:8443/API/DLMS');
            const data = await response.json();

            // Очищаем массив от null значений и исключаем Dedic и Финляндия Aeza
            const validServers = data.filter(server => server !== null && server.name !== 'Dedic' && server.name !== 'Финляндия Aeza'  && server.name !== 'Германия Aeza');

            // Находим сервер с минимальной загрузкой
            const bestServer = validServers.reduce((prev, current) => {
                return parseFloat(prev.load) < parseFloat(current.load) ? prev : current;
            });

            // Добавляем эмодзи на основе названия
            const flag = flags.find(flag => flag.name === bestServer.name);

            return { ...bestServer, emoji: flag ? flag.emoji : '' };
        } catch (error) {
            console.error('Error fetching best location:', error);
            return { name: 'Unknown', emoji: '❓' };
        }
    };

    const flags = [
        { name: 'Германия', emoji: '🇩🇪' },
        { name: 'США', emoji: '🇺🇸' },
        { name: 'Австрия', emoji: '🇦🇹' },
        { name: 'Нидерланды', emoji: '🇳🇱' },
        { name: 'Литва', emoji: '🇱🇹' },
        { name: 'Латвия', emoji: '🇱🇻' },
        { name: 'Франция', emoji: '🇫🇷' },
        { name: 'Великобритания', emoji: '🇬🇧' },
        { name: 'Норвегия', emoji: '🇳🇴' },
        { name: 'Финляндия', emoji: '🇫🇮' },
        { name: 'Польша', emoji: '🇵🇱' },
        { name: 'Македония', emoji: '🇲🇰' },
        { name: 'Швеция', emoji: '🇸🇪' },
        { name: 'Швейцария', emoji: '🇨🇭' }
    ];

    return (
        <div className="flag-slider-container">
            <div className="location-title">Подбор локации 🧭</div>
            {isSliding ? (
                <div className="flag-slider">
                    <div className="flag" key={currentFlagIndex}>
                        {flags[currentFlagIndex].emoji}
                    </div>
                </div>
            ) : (
                <div className="final-flag">
                    {finalFlag?.name} {finalFlag?.emoji}
                </div>
            )}
        </div>
    );
}

export default Test_location;
