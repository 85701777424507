import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ConnectOutline = () => {
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const encodedUrl = params.get('url');

        if (encodedUrl) {
            const url = decodeURIComponent(encodedUrl);
            console.log(url)
            window.location.href = url;
        } else {
            console.log('No URL provided');
        }
    }, [location]);

    return (
        <div>
        </div>
    );
};

export default ConnectOutline;
