import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTelegram } from "../hooks/useTelegram";

function PaymentRedirect() {
    const { tg } = useTelegram();
    const { id } = useParams();
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [showEmailModal, setShowEmailModal] = useState(true);
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    }, [redirectUrl]);

    const handleConfirmEmail = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && emailRegex.test(email)) {
            try {
                const data = {
                    userId: id,
                    sum: 99,
                    method: 'card',
                    email: email // Отправляем email
                };
                const response = await axios.post('https://click-vpn.ru:8443/API/create_url', data);
                window.location.href = response.data;
            } catch (error) {
                console.error('Ошибка при запросе на создание URL:', error);
            }
        } else {
            alert('Пожалуйста, введите корректный адрес электронной почты.');
        }
    };

    return (
        <div>
            {showEmailModal && (
                <div className="email-modal">
                    <div className="modal-content">
                        <h2>Ваш email для квитанции</h2>
                        <input
                            type="email"
                            placeholder="example@mail.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button onClick={handleConfirmEmail}>Оплатить</button>
                        <button onClick={() => setShowEmailModal(false)}>Отмена</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PaymentRedirect;
