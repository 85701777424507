import React, { useState, useEffect } from 'react';
import '../Landing.css';

function LetterByLetterText({ text, delay }) {
    const [visibleText, setVisibleText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (currentIndex < text.length) {
            const timeout = setTimeout(() => {
                setVisibleText((prevText) => prevText + text[currentIndex]);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }, delay);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [text, delay, currentIndex]);

    return <span1>{visibleText}</span1>;
}

export default LetterByLetterText;
