import '../App.css';
import './payment.css';

import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import logo from "../Logo.svg";
import LetterByLetterText from "../components/LetterByLetterText";
import or from "./png/Content divider.svg";

function Payment() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState();
    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [email, setEmail] = useState('');
    const subscriptionCardRef = useRef(null);
    const [buttonWidth, setButtonWidth] = useState(0);

    useEffect(() => {
        const preventTouchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };

        window.addEventListener('touchstart', preventTouchZoom, { passive: false });
        return () => {
            window.removeEventListener('touchstart', preventTouchZoom);
        };
    }, []);

    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        if (subscriptionCardRef.current) {
            setButtonWidth(subscriptionCardRef.current.offsetWidth);
        }
    }, [isLoading, selectedPlan]);

    async function fetchRedirectUrl(sum, method, email = '') {
        try {
            const data = {
                userId: id,
                sum: sum,
                method: method,
                email: email // Отправляем email, если он есть
            };

            const response = await axios.post('https://click-vpn.ru:8443/API/create_url', data);
            window.location.href = response.data;
        } catch (error) {
            console.error('Ошибка при запросе на создание URL:', error);
        }
    }

    const handlePayment = (method, sum) => {
        if (method === 'card') {
            setShowEmailModal(true);
        } else {
            fetchRedirectUrl(sum, method);
        }
    };

    const handleConfirmEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && emailRegex.test(email)) {
            fetchRedirectUrl(selectedPlan, 'card', email);
            setShowEmailModal(false);
        } else {
            alert('Пожалуйста, введите корректный адрес электронной почты.');
        }
    };


    const selectPlan = (plan) => {
        setSelectedPlan(plan);
        setShowPaymentOptions(true);
        navigator.vibrate(20);
    };

    return (
        <div className="Payment">
            <div className="loading-container">
                <img src={logo} className={`loading-logo ${isLoading ? 'slide-in' : 'move-right'}`} alt="Загрузка..." />
                {!isLoading && (
                    <div className="click-vpn">
                        <LetterByLetterText text="Click Pay" delay={40} />
                    </div>
                )}
            </div>
            {!isLoading && (
                <div className="subscription-container">
                    <div className="pay-text"> Выбери вариант подписки: </div>
                    <div className={`subscription-card highlight ${selectedPlan === 950 ? 'selected' : ''}`} onClick={() => selectPlan(950)}>
                        <div className="best-value">Лучшее предложение</div>
                        <div className="subscription-title">12 месяцев</div>
                        <div className="subscription-price">79 рублей в месяц</div>
                        <div className="subscription-savings">Скидка 20%</div>
                    </div>
                    <div className={`subscription-card ${selectedPlan === 520 ? 'selected' : ''}`} onClick={() => selectPlan(520)}>
                        <div className="subscription-title">6 месяцев</div>
                        <div className="subscription-price">86 рублей в месяц</div>
                        <div className="subscription-savings">Скидка 13%</div>
                    </div>
                    <div
                        className={`subscription-card ${selectedPlan === 99 ? 'selected' : ''}`}
                        onClick={() => selectPlan(99)}
                        ref={subscriptionCardRef}
                    >
                        <div className="subscription-title">1 месяц</div>
                        <div className="subscription-price">99 рублей в месяц</div>
                        <div className="subscription-savings">Чтобы попробовать</div>
                    </div>
                </div>
            )}
            {showPaymentOptions && (
                <div className="payment-options">
                    <button className="payment-button1" onClick={() => handlePayment('card', selectedPlan)} style={{ width: buttonWidth }}>Оплатить картой РФ / СБП 💳</button>
                    <img className={'or'} src={or} alt="or" />
                    <button className="payment-button2" onClick={() => handlePayment('crypto', selectedPlan)} style={{ width: buttonWidth }}>Оплатить криптовалютой 🪙💱</button>
                </div>
            )}
            {showEmailModal && (
                <div className="email-modal">
                    <div className="modal-content">
                        <h2>Ваш email для квитанции</h2>
                        <input
                            type="email"
                            placeholder="example@mail.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button onClick={handleConfirmEmail}>Оплатить</button>
                        <button onClick={() => setShowEmailModal(false)}>Отмена</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Payment;
