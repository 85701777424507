import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import '../premium.css';
import passImage from './pass.png';
import animationData from './Animation.json';
import animationServer from './AnimationServer.json';
import animationClient from './AnimationClient.json';
import animationChat from './AnimationChat.json';
import {useTelegram} from "../hooks/useTelegram"
import exitIco from "../CRM/src/img/exit.png";

export default function ModelViewer() {
    const containerRef = useRef(null);
    const {tg} = useTelegram();

    useEffect(() => {
        const container = document.getElementById('lcontainer'); // Получите элемент, в который встроить анимацию
        const anim = lottie.loadAnimation({
            container: container,
            animationData: animationData, // Подставьте ваш JSON-объект анимации
            loop: true, // Опционально: сделать анимацию цикличной
            autoplay: true, // Опционально: воспроизвести анимацию автоматически
        });

        return () => {
            anim.destroy(); // Опционально: уничтожить анимацию при размонтировании компонента
        };
    }, []);

    useEffect(() => {
        const container = document.getElementById('animationServer');
        const anim = lottie.loadAnimation({
            container: container,
            animationData: animationServer,
            loop: false,
            autoplay: false,
        });

        let isForward = true;

        const playAnimation = () => {
            if (isForward) {
                anim.setDirection(1);
                anim.play();
            } else {
                anim.setDirection(-1);
                anim.play();
            }
            isForward = !isForward;
        };

        // Запустить анимацию без задержки
        anim.play();
        // Начать воспроизведение анимации с интервалом после первого проигрывания
        const animationInterval = setInterval(playAnimation, 6500);

        return () => {
            clearInterval(animationInterval);
            anim.destroy();
        };
    }, []);

    useEffect(() => {
        const container = document.getElementById('animationClient'); // Получите элемент, в который встроить анимацию
        const anim = lottie.loadAnimation({
            container: container,
            animationData: animationClient, // Подставьте ваш JSON-объект анимации
            loop: true, // Опционально: сделать анимацию цикличной
            autoplay: true, // Опционально: воспроизвести анимацию автоматически
        });

        return () => {
            anim.destroy(); // Опционально: уничтожить анимацию при размонтировании компонента
        };
    }, []);

    useEffect(() => {
        const container = document.getElementById('animationChat'); // Получите элемент, в который встроить анимацию
        const anim = lottie.loadAnimation({
            container: container,
            animationData: animationChat, // Подставьте ваш JSON-объект анимации
            loop: true, // Опционально: сделать анимацию цикличной
            autoplay: true, // Опционально: воспроизвести анимацию автоматически
        });

        return () => {
            anim.destroy(); // Опционально: уничтожить анимацию при размонтировании компонента
        };
    }, []);

    tg.setHeaderColor('#000000')
    tg.expand()

    const handleBuy2 = () => {
        tg.HapticFeedback.notificationOccurred('success')
        window.open(`https://click-vpn.ru/new_payment_premium/${tg.initDataUnsafe?.user?.id}`, "_blank", "noopener noreferrer");
    };

    return (
        <div className="Apps">
            <div>
                <img src={passImage} alt="Pass" className="centered-image" loading="eager"/>
            </div>

            <div className="container" ref={containerRef}>
                <div className="content">
                    <h3>Эксклюзивные функции PREMIUM:</h3>

                    <div className="premium-features">
                        <div>Выделенные серверы со скоростью до 1000 Мбит/с</div>
                    </div>
                    <div className="Servers">
                        <div id="animationServer"></div>
                    </div>
                    <br />

                    <div className="premium-features">
                        <div>Возможность смены локации VPN в боте без замены ключа доступа</div>
                    </div>
                    <div className="Location">
                        <div id="lcontainer"></div>
                    </div>
                    <br />

                    <div className="premium-features">
                        <div>Приоритетное техническое консультирование по услугам</div>
                    </div>
                    <div className="Client">
                        <div id="animationClient"></div>
                    </div>
                    <br />

                    <div className="premium-features">
                        <div>Эксклюзивный чат с разработчиками и возможность влиять на развитие сервиса</div>
                    </div>
                    <div className="Client">
                        <div id="animationChat"></div>
                    </div>
                    <br />

                </div>
            </div>
            <button className="buy-button-2" onClick={handleBuy2}>
                Подключить
            </button>
        </div>
    );
}
