import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import logo from "./Logo.svg"
import LetterByLetterText from "./components/LetterByLetterText";
import Wiki from "./Wiki/Wiki"
import DropDown from "./components/DropDown";
import Load from "./components/Load";
import Switch from "./components/Switch"
const tg = window.Telegram.WebApp;

function App() {
    const [PayDay, setPayDay] = useState([]);
    const [accessUrlSS, setaccessUrlSS] = useState([]);
    const [accessUrlVLESS, setaccessUrlVLESS] = useState([]);
    const [autoPayBool, setautoPayBool] = useState([]);
    const [payment, setPayment] = useState([]);
    const [friendCount, setfriendCount] = useState([]);
    const [RefUrl, setRefUrl] = useState([]);
    const [Premium, setPremium] = useState([]);
    const [currentServer, setcurrentServer] = useState([]);
    const [currentProtocol, setcurrentProtocol] = useState([]);
    const [showAccessUrl, setShowAccessUrl] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);

    /////////////////////////////
    // Отключение масштабирвоания
    /////////////////////////////
    useEffect(() => {
        const preventTouchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };

        window.addEventListener('touchstart', preventTouchZoom, { passive: false });
        return () => {
            window.removeEventListener('touchstart', preventTouchZoom);
        };
    }, [])
    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);
    /////////////////////////////
    /////////////////////////////

    function copyToClipboard(text) {
        // Замена %23 на #
        const updatedText = text.replace(/%23/g, '#');

        const textField = document.createElement('textarea');
        textField.innerText = updatedText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toast.success('Скопировано', { autoClose: 1500 });
    }


    useEffect(()=>{
        tg.ready();
    },[])

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Закрыть'
        })
    }, [])

    tg.MainButton.show();
    tg.setHeaderColor('#1A659E')

    const onClose = () => {
        tg.HapticFeedback.notificationOccurred('success');
        tg.close()
    }

    tg.onEvent('mainButtonClicked', onClose)
    tg.MainButton.color = "#1A659E";

    const toggleSettingsVisible = () => {
        setIsSettingsVisible(!isSettingsVisible);
    };

    const Prolong = () => {
        tg.HapticFeedback.notificationOccurred('success');
        window.open(`https://click-vpn.ru/new_payment/${tg.initDataUnsafe?.user?.id}`, "_blank", "noopener noreferrer");
    }

    const ProlongPremium = () => {
        tg.HapticFeedback.notificationOccurred('success');
        window.open(`https://click-vpn.ru/new_payment_premium/${tg.initDataUnsafe?.user?.id}`, "_blank", "noopener noreferrer");
    }

    useEffect(() => {
        axios.get(`https://click-vpn.ru:8443/API/userAccount/${tg.initDataUnsafe?.user?.id}`)
            .then(response => {
                // Обработка успешного ответа
                setaccessUrlSS(response.data.accessKey)
                setaccessUrlVLESS(response.data.subscribe_link)
                setPayDay(response.data.date)
                setautoPayBool(response.data.autoPayBool)
                setPayment(response.data.payment)
                setfriendCount(response.data.friendCount)
                setPremium(response.data.premium)
                setcurrentServer(response.data.currentServer)
                setcurrentProtocol(response.data.currentProtocol)
                setRefUrl(`https://t.me/First_VPN_Click_bot?start=${response.data.id}`)
                setShowAccessUrl(response.data.accessKey)
                setTimeout(() => {
                    setIsLoading(false);
                }, 2000)
            })
            .catch(error => {
                // Обработка ошибки
                console.error(error);
            })
    }, []);

    const handleDisableAutoPayment = () => {
        tg.HapticFeedback.notificationOccurred('error');

        tg.showConfirm('Вы уверены, что хотите отключить подписку?', (confirmation) => {
            if (confirmation) {
                const data = {
                    userId: tg.initDataUnsafe?.user?.id,
                };
                axios.post(`https://click-vpn.ru:8443/API/delete_autopay`, data)
                    .then(() => {
                        tg.showAlert(`Подписка отключена, оплачено до ${PayDay}`);
                    })
                    .catch(error => {
                        console.error('Error disabling subscription:', error);
                        tg.showAlert('Произошла ошибка при отключении подписки. Пожалуйста, попробуйте снова.');
                    });
            }
        });
    };

    const handleGift = () => {
        tg.HapticFeedback.notificationOccurred('success');

        window.open(`https://click-vpn.ru/new_payment_gift/${tg.initDataUnsafe?.user?.id}`, "_blank", "noopener noreferrer");
    }

    const handleOpenOutline = () => {
        tg.showConfirm(
            "Открыть Outline?",
            function(confirmation) {
                if (confirmation) {
                    window.open(`https://click-vpn.ru/ConnectOutline?url=${accessUrlSS}`, "_blank", "noopener noreferrer");
                }
            }
        );
    };

    const handleOpenVLESS = () => {
        tg.showConfirm(
            "Открыть приложение для VLESS?",
            function(confirmation) {
                if (confirmation) {
                    window.open(`https://click-vpn.ru/ConnectVLESS?url=${accessUrlVLESS}`, "_blank", "noopener noreferrer");
                }
            }
        );
    };

    return (
        <div className="App">
            <ToastContainer />
            <div className="loading-container">
                <img src={logo} className={`loading-logo ${isLoading ? 'slide-in' : 'move-right'}`} alt="Загрузка..." />
                {!isLoading && <div className="click-vpn">
                    {Premium === true ? (
                        <div className="li-text">
                            <LetterByLetterText text="Click PREMIUM" delay={40} />
                        </div>
                    ) : <LetterByLetterText text="Click VPN" delay={40} />}
                </div>}
            </div>
            {isLoading ? (
                <div>
                </div>
            ) : (
                <>
                    {accessUrlSS === 'У тебя пока нет ключа доступа' ? (
                        <>
                            <div className="NoSub">У тебя пока нет ключа доступа.</div>
                            <div className="NoSub">Чтобы тут что-то появилось - нужно оплатить.</div>
                            <button className="second-copy-button" onClick={() => {
                                Prolong()
                            }}>
                                Купить подписку
                            </button>
                            <Wiki />
                        </>
                    ) : (
                        <>
                            <div className="access-url-container" onClick={() => {
                                handleGift()
                            }}>
                                <div className="gift-text">
                                    Подарить VPN близким 🎁
                                </div>
                            </div>

                            <div className="text">Оплачено до:</div>
                            <div className="access-url-container">{PayDay}</div>
                            {Premium === true ? (
                                <button className="second-copy-button" onClick={() => {
                                    ProlongPremium()
                                }}>
                                    Продлить подписку
                                </button>
                            ) : <button className="second-copy-button" onClick={() => {
                                Prolong()
                            }}>
                                Продлить подписку
                            </button>}

                            <div className="text">Локация:</div>
                            <div className="access-url-container" onClick={() => {
                                tg.HapticFeedback.notificationOccurred('success');
                            }}>
                                <DropDown currentServer={currentServer}/>
                            </div>

                            <>
                                <div className="access-url-container" onClick={() => {
                                    tg.HapticFeedback.impactOccurred('rigid')
                                }}>
                                    <Load/>
                                </div>
                            </>

                            <div className="text">Ключи доступа:</div>
                            <div className="access-url-container" onClick={() => {
                                tg.HapticFeedback.impactOccurred('rigid')
                                copyToClipboard(accessUrlSS)
                                handleOpenOutline()
                            }}>
                                Скопировать ключ Outline 📝
                            </div>
                            {currentProtocol === 'VLESS' ? (
                                <>
                                    <div className="access-url-container" onClick={() => {
                                        tg.HapticFeedback.impactOccurred('rigid')
                                        copyToClipboard(accessUrlVLESS)
                                        handleOpenVLESS()
                                    }}>
                                        Скопировать ключ VLESS 📝
                                    </div>
                                </>
                            ) : <>
                            </>}

                            <div className="access-url-container">
                                {payment && (
                                    <Switch currentServer={currentProtocol}/>
                                )}
                            </div>

                            <div className="text">Реферальная ссылка:</div>
                            <div className="access-url-container">{RefUrl}</div>
                            {friendCount && (
                                <div className="access-url-container">Друзей приглашено: {friendCount}</div>
                            )}
                            <button className="second-copy-button" onClick={() => {
                                tg.HapticFeedback.impactOccurred('rigid');
                                copyToClipboard(RefUrl)
                            }}>
                                Скопировать
                            </button>

                            <div className="access-url-container">
                                <button
                                    className="SettingsButton"
                                    onClick={() => {
                                        toggleSettingsVisible(); // добавлен вызов функции
                                        tg.HapticFeedback.impactOccurred('rigid');
                                        setTimeout(() => {
                                            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                                        }, 200);
                                    }}
                                >
                                    Дополнительные настройки ⚙️
                                </button>

                                <section className={`Settings ${isSettingsVisible ? 'visible' : ''}`}>
                                    {autoPayBool && (
                                        <section className="DisableAutoPayment">
                                            <button className="AutoPayment-button" onClick={handleDisableAutoPayment}>
                                                Отключить подписку
                                            </button>
                                        </section>
                                    )}
                                </section>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default App;
