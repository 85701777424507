import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../Logo.svg';
import tgImg from './src/img/telegram-1.svg'
import './Login.css';
import LetterByLetterText from "../components/LetterByLetterText";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [loginMessage, setLoginMessage] = useState('');
    const [showCodeInput, setShowCodeInput] = useState(false);
    const navigate = useNavigate();
    const authCodeInputRef = useRef(null);

    const handleLogin = async () => {
        try {
            const response = await fetch('https://click-vpn.ru:8443/API/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                const { success, message } = await response.json();
                if (success) {
                    setLoginMessage(message);
                    setShowCodeInput(true);
                } else {
                    setLoginMessage(message);
                }
            } else {
                setLoginMessage('Ошибка. Неверные данные.');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setLoginMessage('Ошибка. Сервер не ответил.');
        }
    };

    const handleVerifyCode = async () => {
        try {
            const response = await fetch('https://click-vpn.ru:8443/API/login/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, authCode }),
            });

            if (response.ok) {
                const { success, message, token } = await response.json();
                if (success) {
                    setLoginMessage(message);
                    setTimeout(() => {
                        localStorage.setItem('token', token);
                        console.log(token)

                        navigate('/CRM');
                    }, 1000);
                } else {
                    setLoginMessage(message);
                }
            } else {
                setLoginMessage('Ошибка проверки кода');
            }
        } catch (error) {
            setLoginMessage('Ошибка запроса к серверу.');
        }
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (showCodeInput) {
                    handleVerifyCode();
                } else {
                    handleLogin();
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleLogin, handleVerifyCode, showCodeInput]);

    useEffect(() => {
        // Установка фокуса на поле ввода кода при отображении этого поля
        if (showCodeInput) {
            authCodeInputRef.current.focus();
        }
    }, [showCodeInput]);

    useEffect(() => {
        // Автоматическое нажатие кнопки при достижении 6 символов в поле ввода кода
        if (authCode.length === 6) {
            handleVerifyCode();
        }
    }, [authCode]);

    const handleTelegramLinkClick = () => {
        window.open("https://t.me/First_VPN_Click_bot", "_blank", "noopener noreferrer");
    };

    return (
        <div className="login-container">
            <img src={logo} alt="Logo" className="login-logo" />
            <div className="login-form">
                <h3 className="login-heading">Вход в аккаунт</h3>
                <label htmlFor="username">Имя пользователя:</label>
                <input
                    className="login-input"
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />

                <label htmlFor="password">Пароль:</label>
                <input
                    className="login-input"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <button className="login-button" onClick={handleLogin}>Войти</button>

                {showCodeInput && (
                    <>
                        <label className="login-label" htmlFor="authCode">Код аутентификации:</label>
                        <input
                            className="login-input"
                            type="text"
                            id="authCode"
                            value={authCode}
                            onChange={(e) => setAuthCode(e.target.value)}
                            ref={authCodeInputRef}
                            inputMode="numeric"
                        />
                        <button className="login-button" onClick={handleVerifyCode}>Подтвердить</button>
                    </>
                )}

                <div className="loginMessage" key={loginMessage}>
                    {loginMessage && <LetterByLetterText text={loginMessage} delay={30} />}
                </div>

            </div>

            <div className="telegram-link" onClick={handleTelegramLinkClick}>
                <div className="telegram-content">
                    <img src={tgImg} alt="Telegram Icon" className="login-tg" />
                    Telegram бот
                </div>
            </div>

        </div>

    );
};

export default Login;
